@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./Inter-VariableFont_slnt\,wght.ttf) format('truetype');
    font-weight: 100 900;
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Permanent Marker';
    src: local('Permanent Marker'), url(./PermanentMarker-Regular.ttf) format('truetype');
    font-weight: 400;
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }